<template>
  <module-list-layout :createAction="createAction" :header="false" :module="module" style="width: 1300px !important;" v-if="amReady" class="mt-3">
    <exit-button></exit-button>
    <h6>list Office Insurance  <el-button @click="createAction" size="small" class="p-2" type="primary" icon="el-icon-plus">Create</el-button></h6>

    <el-dialog :visible.sync="dialogVisible" custom-class="el-custom-dialog" title="" top="3vh" width="1200px">
      <moduleForm
          :autoBind="false"
          :bindingForView="true"
          :dataToBind="dataToBind"
          :edit-mode="editMode"
          :formHeader="true"
          :formTitle="((editMode)? 'Update ' : 'Create ') + module.info.caption"
          :moduleDeleteAction='moduleFormDeleteAction'
          :on-create-callback="afterCreate"
          :on-edit-callback="afterEdit"
          v-if="dialogVisible"></moduleForm>
    </el-dialog>

    <div class="row">
      <div class="col-12 col-md-6">
        <dg-text-field
            :field_span="24"
            :label_span="0"
            :liveUpdate="true"
            :setFocus="true"
            :defaultValue="userSearch"
            :translateLabel="false"
            @amUpdated="userSearchUpdated"
            class="mt-1"
            field_mark=""
            label=""
            placeholder="search here"
            style="max-width: 400px"></dg-text-field>
      </div>
      <div class="col-12 col-md-6 float-right text-right">
         <span style="display: inline-block;margin-right: 20px;">
               <small> records: {{ recordsCounter }} | {{ pageChunkSize }} record per page</small>
        </span>
        <pagination
            :currentPage="currentPage"
            :margin-pages="2"
            :navigateHandler="navigateToPage"
            :page-count="Math.ceil(recordsCounter / pageChunkSize)"
            :page-range="4"
            class="mt-1 d-inline-block"
            paginateClass=""
            style="margin-right: 20px;"></pagination>

        <el-button @click="removeSelectedAction" icon="el-icon-delete" size="mini" type="danger"
                   v-if="listConfig.selection_column.show">Selected
        </el-button>
      </div>
    </div>

    <el-table :data="tableData" @selection-change="handleTableSelectionChange" border cell-class-name="p-1" stripe
              style="width: 100%">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
              :data="[props.row]"
              border
              cell-class-name="p-1"
              header-cell-class-name="app-light-silver-background p-1"
              style="width: 100%;">

            <el-table-column
                label="Contact Person"
                prop="contact_person"
                sortable
                width="180">
            </el-table-column>

            <el-table-column
                label="Phone"
                prop="contact_work_phone__complete"
                width="180">
              <template slot-scope="scope">
                {{ scope.row.contact_work_phone__complete.replaceAll('null', '') }}
              </template>
            </el-table-column>

            <el-table-column
                label="Fax"
                prop="contact_fax__complete"
                width="180">
              <template slot-scope="scope">
                {{ scope.row.contact_fax__complete.replaceAll('null', '') }}
              </template>
            </el-table-column>

            <el-table-column
                label="Email"
                prop="email"
                width="180">
              <template slot-scope="scope">
                {{ scope.row.email.replaceAll('null', '') }}
              </template>
            </el-table-column>

            <el-table-column
                label="Policy Number"
                prop="policy_number"
                width="240">
              <template slot-scope="scope">
                {{ scope.row.policy_number.replaceAll('null', '') }}
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          label="Type"
          prop="type_id"
          width="180">
        <template slot-scope="scope">
          <dropdownChanger :confirmChanges="true" :fieldId="scope.row.id" :options="typesDropdown"
                           :value="scope.row.category_id"
                           fieldName="category_id" updateApi="Office-Insurance-api/update"></dropdownChanger>
        </template>
      </el-table-column>
      <el-table-column
          label="Company Name"
          prop="company_name"
          width="250">
        <template slot-scope="scope">
          <smartInput :customParams="{data:scope.row}" :inputValue="scope.row.company_name"
                      :noCopy="true"
                      :saveAction="updateField" editContainerStyle="top: 0; position: relative;" inputWidth='170px'
                      name='company_name'></smartInput>
        </template>
      </el-table-column>
      <el-table-column
          label="Starting Date"
          prop="starting_date"
          width="130">
      </el-table-column>
      <el-table-column
          label="Expiry Date"
          prop="expiry_date"
          width="130">
      </el-table-column>
      <el-table-column
          label="Fees Schedule"
          prop="fees_period_id"
          width="200">
        <template slot-scope="scope">
        {{getLabelFromOptions(scope.row.fees_period_id, feesPeriodDropdown)}}
        </template>
      </el-table-column>
      <el-table-column
          label="Amount"
          prop="amount"
          width="150">
        <template slot-scope="scope">
          {{ $services['NumUtil'].format(scope.row.amount) }}
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          label="Actions"
          prop="">
        <template slot-scope="scope">
          <vDropdown :actionsHandler="actionsHandler" :items="usersActions"
                     :params="{data: scope.row, rowIndex: scope.$index + 1} "
                     :userProfileId="$store.state['App-User-state'].profile.id"></vDropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :append-to-body="true" :visible.sync="deleteSelectedDialog" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">Are you sure you want to remove all records ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteSelectedDialog = false" size="small">Cancel</el-button>
        <el-button @click="removeSelectedApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">You want to remove this record ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>
  </module-list-layout>
</template>

<script>
import module from './../../index'
import TaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";

export default {
  mixins: [$mixins['listView']],
  components: {
    moduleForm: () => import('../../form/View'),
    'dropdownChanger': () => import('@/components/dropdown-changer/View'),
    'exit-button': () => import('@/components/exit-button/View')
  },
  methods: {
    getLabelFromOptions(val, options) {
      for (let i in options) {
        if (options[i].value == val) return options[i].label
      }
      return ''
    },
    ...$mapActions(module.info.fullName + '-api', {
      getEntityList: 'list',
      updateEntity: 'update',
      multipleDelete: "multipleDelete"
    }),
  },
  computed: {...$mapState(module.info.fullName + '-api', ['pageChunkSize']),},
  beforeMount() {
    /*let promises = []
    promises.push($store.dispatch('Product-Brand-api/getDropDownList').then())
    Promise.all(promises).then((values) => {
      this.loadPageFromUrl()
    })*/

    this.loadPageFromUrl()
  },
  data() {
    return {
      feesPeriodDropdown: TaxReportingPeriods,
      typesDropdown: [
        {
          label: 'Car',
          value: 1
        },
        {
          label: 'Residential',
          value: 2
        },
        {
          label: 'Commercial',
          value: 3
        }
      ],
      module: {...module},
      usersActions: [
        {
          label: ' View / Edit',
          icon: 'el-icon-edit-outline',
          action: 'editAction',
          notForProfiles: []
        },
        {
          label: 'Remove',
          icon: 'el-icon-delete',
          action: 'removeAction',
        },
      ]
    };
  }
}
</script>
